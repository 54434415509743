.error-page {
  background: $backgroundWhiteColor;
  background-size: cover;
  margin: -148px 0 0;
  padding: 155px 0 10px;

  @media (min-width: $screen-mobile-landscape) {
    padding: 172px 0 24px;
  }

  @media (min-width: $screen-tablet-portrait) {
    padding: 180px 0 40px;
  }

  &--400,
  &--404,
  &--500,
  &--502,
  &--503,
  &--504 {
    background: url('/assets/vinos/frontend-core/staticImages/error404.jpg') no-repeat right;
    background-size: cover;
  }

  &__container {
    @include container();
  }

  &__content {
    background: $sandHoverColor;
    text-align: left;
    max-width: 100%;
    padding: 16px;
    margin-left: 0;
    border-radius: 8px;

    @media (min-width: $screen-mobile-landscape) {
      padding: 24px;
    }

    @media (min-width: $screen-tablet-landscape) {
      max-width: 75%;
      padding: 40px;
    }

    @media (min-width: $screen-laptop) {
      max-width: 605px;
      padding: 40px;
    }
  }

  &__logo {
    width: 127px;
  }

  &__headline {
    color: $textDarkestColor;
    display: block;
    width: 100%;
    padding: 0;
    line-height: 1.1;
    font-size: 24px;
    font-family: $fontHeadlineFamily;
    font-weight: $fontWeightBold;
    margin: 24px 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
      margin: 16px 0;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }

  &__subline,
  &__text {
    font-size: 13px;
    line-height: 1.3;
    color: $textBaseColor;
    margin: 8px 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }
  }

  &__subline {
    font-weight: $fontWeightBold;
    margin: 8px 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 8px 0 32px;
    }
  }

  &__search {
    position: relative;
    max-width: 500px;
    margin: 8px 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 8px 0 32px;
      max-width: 400px;
    }

    .input__wrapper {
      border: 1px solid $whiteColor;
      border-radius: 1.8px;

      &:hover {
        border: 1px solid $inputSuccessBorderColor;
      }

      .input__field::placeholder {
        text-align: left;
      }
    }
  }

  &__button {
    @include switchButtonStyle('secondary>primary');
    margin: 0 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 40px;
    }
  }

  &__disclaimer {
    font-size: 13px;
    line-height: 1.3;
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }

    a {
      color: $orangeColor;
      text-decoration: underline;
    }
  }
}
